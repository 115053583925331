import React, { useEffect, useState } from "react";
import { Button, Toolbar, AppBar, List, ListItem } from "react95";
import Wallet from "src/views/Wallet";
import Router from "src/views/Router";
import Info from "src/views/Info";
import Paint from "src/views/Paint";
import Minesweeper from "src/views/Minesweeper";
import { checkMetaMask } from "src/hooks/operations";
import Timer from "src/views/Timer";
export default function BottomBar({ crtAccount, chainId }) {
  const [open, setOpen] = useState({
    wallet: { active: true, open: true },
    router: { active: false, open: false },
    weth: { active: false, open: false },
    paint: { active: false, open: false },
    minesweeper: { active: false, open: false },
  });
  const [start, setStart] = useState(false);
  const [activeRauterTab, setActiveRauterTab] = useState(0);

  const [isConnected, setIsConnectd] = useState(false);
  const [isLoading, setIsloading] = useState(true);

  const changeTab = (name, activeTab) => {
    if (activeTab) {
      setActiveRauterTab(activeTab);
    } else {
      setActiveRauterTab(0);
    }
    const temp = { ...open };
    Object.keys(temp).forEach((key) => {
      if (key !== name) {
        temp[key] = { ...temp[key], active: false };
      }
    });
    temp[name] = { active: true, open: true };
    setOpen(temp);
  };
  const closeTab = (name) => {
    const temp = { ...open };
    const crtActive = temp[name].active;
    temp[name] = { active: false, open: false };

    if (crtActive) {
      const data = Object.keys(temp).map((key) => {
        return { key, value: temp[key] };
      });
      for (let i = 0; i < data.length; i++) {
        if (data[i].value.open) {
          temp[data[i].key] = { active: true, open: true };
          break;
        }
      }
    }

    setOpen(temp);
  };
  useEffect(() => {
    const isMetaMask = checkMetaMask();
    if (isMetaMask && crtAccount !== null) {
      setIsConnectd(true);
      setIsloading(false);
    } else {
      setIsConnectd(false);
    }
    setIsloading(false);
  }, [crtAccount]);

  useEffect(() => {
    document.addEventListener("click", () => {
      setStart(false);
    });
  }, []);

  return (
    <>
      <div
        className="desktop-icon paint-button"
        onClick={() => changeTab("paint")}
      >
        <img src="images/paint-32x32.png" alt="" />
        Paint
      </div>
      <div
        className="desktop-icon minesweeper-button"
        onClick={() => changeTab("minesweeper")}
      >
        <img src="images/minesweeper-32x32.png" alt="" />
        Minesweeper
      </div>
      {start && (
        <List className="start-menu">
          <ListItem onClick={() => changeTab("wallet")}>
            <span role="img">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAHlBMVEUAAAAAAACAgADAwMCAgID///+AAAD//wAAAP//AACHNrbBAAAAAXRSTlMAQObYZgAAAAFiS0dEBfhv6ccAAAAHdElNRQfiBhoANiJ7wILjAAABBUlEQVQoz42RsW6DMBCGnSgPwB0W7ohzKMz2Ne1aQ6WqY5UXKEt2KJLnbDxBn7cHIgIy9d/u0//b/9lK/Us7ED3OKwKzkof5DmY/kN0C7d06gYys14AuusEVQHao1xF6gZTtAogda/bJHaTMlzNWoBZAZ3bFt9ofj/nUg70cWieqjLGagGROBSX72Js+HzPM/CXLlq9tmCwpVbWTS0vbvhmpt0OfZRgEdBo+BABRBYW0MMbGiHIo++esqq0ytjMjGDSR1f6zMZ0pe1RPw01aZtdfaGOMIVcgBAa4Nj8myN5KBSHDcMvA9ThVFzIiEEM+r3cQBEYMm89LF8P8Cu9bg/TbGhb9AWxsMvM7D72qAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE4LTA2LTI2VDAwOjU0OjM0LTA0OjAwggzQfQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOC0wNi0yNlQwMDo1NDozNC0wNDowMPNRaMEAAAAASUVORK5CYII="
                alt="icon"
              />
            </span>
            Wallet
          </ListItem>
          <ListItem onClick={() => changeTab("router")}>
            <span role="img">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAJ1BMVEUAAACAgIDAwMAAAAD///8AgIAA//8AAP+AAIAAAID//wCAgAD/AADTdjT5AAAAAXRSTlMAQObYZgAAAAFiS0dEBI9o2VEAAAAHdElNRQfiBBMBJTN2lZVeAAABBklEQVQoz1XQsWrDMBAGYBlBabcKefJmp1O8uFKbwdkSlUB2v4K9hlK4vkKeoHSsm0nZoq1Z82A9KYokH9igD37dnQixxWyRWFmFVTYJzKSUU3jrugAuf4W+72/5Vwt3ALDz+QCfPv/MWNkMCBsPwrZF+LDQIWBM8qECBR5elFJ8eFcbhBK7YkQKPsATgG+LlzK+uHaRLo9fc/8F8E1yZYu7oR/2e0JyTGDeAQ2rsASouyNZPanwaNS/itZsAgUvvNygXWr9mADX3BQ/EbLx9De2vxGKup7XSxMgO2xP49nICO1B1x6InTRft6uzEbMwprisj0ZUcQcqLhLfLdmEitXk7PbG/z/0JkoyIM7uGAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0wNC0xOVQwMTozNzo1MS0wNDowMGGLZNcAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMDQtMTlUMDE6Mzc6NTEtMDQ6MDAQ1txrAAAAAElFTkSuQmCC"
                alt="icon"
              />
            </span>
            Router
          </ListItem>
          <ListItem onClick={() => changeTab("paint")}>
            <span role="img">
              <img src="images/paint-32x32.png" alt="icon" />
            </span>
            Paint
          </ListItem>
          <ListItem onClick={() => changeTab("minesweeper")}>
            <span role="img">
              <img src="images/minesweeper-32x32.png" alt="icon" />
            </span>
            Minesweeper
          </ListItem>
        </List>
      )}
      <AppBar id="appBar">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setStart(!start);
              }}
              active={start}
              className={`image-button start ${start ? "active" : ""}`}
            >
              <img src="images/start.png" alt="icon" />
              <span>Start</span>
            </Button>
            <Button
              onClick={() => changeTab("wallet")}
              active={open.wallet.active}
              className={`image-button ${open.wallet.active ? "active" : ""}`}
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAHlBMVEUAAAAAAACAgADAwMCAgID///+AAAD//wAAAP//AACHNrbBAAAAAXRSTlMAQObYZgAAAAFiS0dEBfhv6ccAAAAHdElNRQfiBhoANiJ7wILjAAABBUlEQVQoz42RsW6DMBCGnSgPwB0W7ohzKMz2Ne1aQ6WqY5UXKEt2KJLnbDxBn7cHIgIy9d/u0//b/9lK/Us7ED3OKwKzkof5DmY/kN0C7d06gYys14AuusEVQHao1xF6gZTtAogda/bJHaTMlzNWoBZAZ3bFt9ofj/nUg70cWieqjLGagGROBSX72Js+HzPM/CXLlq9tmCwpVbWTS0vbvhmpt0OfZRgEdBo+BABRBYW0MMbGiHIo++esqq0ytjMjGDSR1f6zMZ0pe1RPw01aZtdfaGOMIVcgBAa4Nj8myN5KBSHDcMvA9ThVFzIiEEM+r3cQBEYMm89LF8P8Cu9bg/TbGhb9AWxsMvM7D72qAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE4LTA2LTI2VDAwOjU0OjM0LTA0OjAwggzQfQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOC0wNi0yNlQwMDo1NDozNC0wNDowMPNRaMEAAAAASUVORK5CYII="
                alt="icon"
              />
              <span>Wallet</span>
            </Button>
            <Button
              onClick={() => changeTab("router")}
              active={open.router.active}
              className={`image-button ${open.router.active ? "active" : ""}`}
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAJ1BMVEUAAACAgIDAwMAAAAD///8AgIAA//8AAP+AAIAAAID//wCAgAD/AADTdjT5AAAAAXRSTlMAQObYZgAAAAFiS0dEBI9o2VEAAAAHdElNRQfiBBMBJTN2lZVeAAABBklEQVQoz1XQsWrDMBAGYBlBabcKefJmp1O8uFKbwdkSlUB2v4K9hlK4vkKeoHSsm0nZoq1Z82A9KYokH9igD37dnQixxWyRWFmFVTYJzKSUU3jrugAuf4W+72/5Vwt3ALDz+QCfPv/MWNkMCBsPwrZF+LDQIWBM8qECBR5elFJ8eFcbhBK7YkQKPsATgG+LlzK+uHaRLo9fc/8F8E1yZYu7oR/2e0JyTGDeAQ2rsASouyNZPanwaNS/itZsAgUvvNygXWr9mADX3BQ/EbLx9De2vxGKup7XSxMgO2xP49nICO1B1x6InTRft6uzEbMwprisj0ZUcQcqLhLfLdmEitXk7PbG/z/0JkoyIM7uGAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0wNC0xOVQwMTozNzo1MS0wNDowMGGLZNcAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMDQtMTlUMDE6Mzc6NTEtMDQ6MDAQ1txrAAAAAElFTkSuQmCC"
                alt="icon"
              />
              <span>Router</span>
            </Button>

            {open.weth.open && (
              <Button
                onClick={() => changeTab("weth")}
                active={open.weth.active}
                className={`image-button ${open.weth.active ? "active" : ""}`}
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAElBMVEUAAACAgIDAwMD///8AAP8AAAAnMJ9BAAAAAXRSTlMAQObYZgAAAAFiS0dEAxEMTPIAAAAHdElNRQfiBhoAKi6UAZOVAAAAtElEQVQoz52RwQ3DIAxFzSH3fKoOULVZAMoCFQOERuy/Sm0whHCsD0n8Yn99fRNJGQArnQXrvX/g7J9eyjVias9Et278/YoffoY6ID9jjPLa20ADoSvUFe923eiViOwFbGuVeKuGDwqEVIB/gL2ADeqjgQR1qsAxKCLiIxYJTmAZnZcgj94HlNCWnljSpLNmmqGpmvwVC/k8Be48lIDhEDgEDKcyDMbTiZsJ0DGDZQaEGZgKfl9+QLkGGjzRAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE4LTA2LTI2VDAwOjQyOjQ2LTA0OjAw08ZnygAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOC0wNi0yNlQwMDo0Mjo0Ni0wNDowMKKb33YAAAAASUVORK5CYII="
                  alt="icon"
                />
                <span>BORE/WETH...</span>
              </Button>
            )}
            {open.paint.open && (
              <Button
                onClick={() => changeTab("paint")}
                active={open.paint.active}
                className={`image-button ${open.paint.active ? "active" : ""}`}
              >
                <img src="images/paint-16x16.png" alt="icon" />
                <span>Paint</span>
              </Button>
            )}
            {open.minesweeper.open && (
              <Button
                onClick={() => changeTab("minesweeper")}
                active={open.minesweeper.active}
                className={`image-button ${
                  open.minesweeper.active ? "active" : ""
                }`}
              >
                <img src="images/minesweeper-32x32.png" alt="icon" />
                <span>Minesweeper</span>
              </Button>
            )}
          </div>
          <div className="d-flex no-responsive hide">
            <Timer />
          </div>
        </Toolbar>
      </AppBar>
      {open.wallet.open && (
        <Wallet
          active={open.wallet.active}
          close={() => closeTab("wallet")}
          select={() => changeTab("wallet")}
          openRouter={(e, active) => {
            e.stopPropagation();
            changeTab("router", active);
          }}
          openWeth={(e) => {
            e.stopPropagation();
            changeTab("weth");
          }}
          openCbtc={(e) => {
            e.stopPropagation();
            changeTab("cbtc");
          }}
          openDai={(e) => {
            e.stopPropagation();
            changeTab("dai");
          }}
          isConnected={isConnected}
          connectWallet={() => setIsConnectd(true)}
          isConnecting={isLoading}
          crtAccount={crtAccount}
          chainId={chainId}
        />
      )}
      {open.router.open && (
        <Router
          active={open.router.active}
          close={() => closeTab("router")}
          select={() => changeTab("router")}
          isConnected={isConnected}
          crtAccount={crtAccount}
          chainId={chainId}
          curtActiveTab={activeRauterTab}
        />
      )}
      {open.paint.open && (
        <Paint
          active={open.paint.active}
          close={() => closeTab("paint")}
          select={() => changeTab("paint")}
          title="untitled - Paint"
        />
      )}
      {open.minesweeper.open && (
        <Minesweeper
          active={open.minesweeper.active}
          close={() => closeTab("minesweeper")}
          select={() => changeTab("minesweeper")}
          title="Minesweeper"
        />
      )}
      {open.weth.open && (
        <Info
          active={open.weth.active}
          close={() => closeTab("weth")}
          select={() => changeTab("weth")}
          title="BORE/WBNB Pancakeswap Pair Information"
        />
      )}
    </>
  );
}
