import { ethers } from "ethers";
import vault from "./abis/vault";
import coreAbi from "./abis/coreAbi";
import pair from "./abis/pair";
import { coreAdd, coreVault, corePair } from "./address";
export function onDragWindow(elmnt) {
  const width = window.innerWidth;
  const height = window.innerHeight;

  let zoom = 1;
  if (width >= 767) {
    const s = Math.min(width / 1280, height / 900);
    zoom = Math.max(0.621, Math.min(s, 1));
  }

  const right = width / zoom - elmnt.offsetLeft - elmnt.offsetWidth;
  const bottom = height / zoom - (elmnt.offsetTop + elmnt.offsetHeight + 44);

  return { left: -elmnt.offsetLeft, top: -elmnt.offsetTop, right, bottom };
}

export function checkMetaMask() {
  let isMetaMask = false;
  if (window.ethereum) {
    isMetaMask = true;
  }
  return isMetaMask;
}

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

export async function getProvider() {
  const provider = await new ethers.providers.Web3Provider(window.ethereum);
  return provider;
}

const getMyAccount = () => {
  const myAdd = window.localStorage.getItem("account");
  return myAdd;
};

export async function connectToWallet() {
  await window.ethereum.request({ method: "eth_requestAccounts" });
  const provider = await getProvider();
  const signer = await provider.getSigner();
  let myAddress = await signer.getAddress();
  window.localStorage.setItem("account", myAddress);
  return "connected";
}

export function accountsChanged(accounts) {
  let currentAccount = null;
  if (accounts.length === 0) {
    // MetaMask is locked or the user has not connected any accounts
    console.log("Please connect to MetaMask.");
    window.localStorage.removeItem("account");
  } else if (accounts[0] !== currentAccount) {
    currentAccount = accounts[0];
    window.localStorage.setItem("account", currentAccount);
    // Do any other work!
  }
  return currentAccount;
}
const getTotalStake = async () => {
  const myAdd = getMyAccount();
  const provider = await getProvider();
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(coreVault, vault, provider);
  const contractWithSigner = contract.connect(signer);
  const totalStake = await contractWithSigner.userInfo(0, myAdd);
  console.log(totalStake);
  console.log(ethers.utils.formatEther(totalStake.rewardDebt));

  return totalStake.amount;
};

export async function getCoreData(add, abi, type) {
  const myAdd = getMyAccount();
  const provider = await getProvider();
  const contract = new ethers.Contract(add, abi, provider);
  // console.log(contract);
  const totalSupply = await contract.totalSupply();
  const totalWallet = await contract.balanceOf(myAdd);
  let totalClaimable = 0;
  let allowance = 0;
  let totalStake = 0;

  if (type === "core") {
    // totalClaimable = await contract.totalLPTokensMinted();
    const contractVault = new ethers.Contract(coreVault, vault, provider);
    totalClaimable = await contractVault.pendingCore(0, myAdd);
  } else {
    // totalClaimable = await contract.getReserves();
    allowance = await contract.allowance(myAdd, coreVault);
    console.log(await getTotalStake());
    totalStake = await getTotalStake();
    // console.log("Testing", allowance);
  }
  return {
    total: ethers.utils.formatEther(totalSupply),
    wallet: ethers.utils.formatEther(totalWallet),
    totalClaimable: ethers.utils.formatEther(totalClaimable),
    allowance: ethers.utils.formatEther(allowance),
    totalStake: ethers.utils.formatEther(totalStake),
  };
}

export async function stakeLP(val) {
  const provider = await getProvider();
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(coreVault, vault, provider);
  const contractWithSigner = contract.connect(signer);
  const deposit = await contractWithSigner.deposit(
    0,
    ethers.utils.parseEther(val)
  );
  await deposit.wait();
  return deposit;
}

export async function approveLP() {
  const myAdd = getMyAccount();
  const provider = await getProvider();
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(corePair, pair, provider);
  const contractWithSigner = contract.connect(signer);
  const totalWallet = await contract.balanceOf(myAdd);
  const approve = await contractWithSigner.approve(coreVault, totalWallet);
  await approve.wait();
  return approve;
}

export async function unStakeLP(val) {
  const provider = await getProvider();
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(coreVault, vault, provider);
  const contractWithSigner = contract.connect(signer);
  const withdraw = await contractWithSigner.withdraw(
    0,
    ethers.utils.parseEther(val)
  );
  await withdraw.wait();
  return withdraw;
}

export async function getPairInfo() {
  const provider = await getProvider();
  const contract = new ethers.Contract(corePair, vault, provider);
  const coreContract = new ethers.Contract(coreAdd, coreAbi, provider);
  const getReserves = await contract.getReserves();
  const lpTokens = await coreContract.totalLPTokensMinted();
  const totalSupply = await coreContract.totalSupply();
  const lpPerUnit = await coreContract.LPperETHUnit();
  return {
    corePrice: ethers.utils.formatEther(getReserves[0]),
    wEth: ethers.utils.formatEther(getReserves[1]),
    lpTokens: ethers.utils.formatEther(lpTokens),
    totalSupply: ethers.utils.formatEther(totalSupply),
    lpPerUnit: ethers.utils.formatEther(lpPerUnit),
  };
}

// const addLiquidity = await contractWithSigner.initialize(
//   "0x82f9Da37cc314C329e8c00168770f19761d386c3",
//   "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
//   "0x6725F303b657a9451d8BA641348b6761A6CC7a17",
//   "0x612aA372B4295F1e7B27d193683E74E8245AF476",
//   "0x00Ed8666418a73e90d06a4F658C0fbf21995f163",
//   "0xC8B0B656878d1699b334bAC58716bf868Ec767C3"
// );
