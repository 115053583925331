import React, { useState, useRef, useEffect } from "react";

import pairAbi from "src/hooks/abis/pair";
import { corePair, workingNetwork } from "src/hooks/address";
import {
  Window,
  WindowHeader,
  WindowContent,
  Tabs,
  Tab,
  TabBody,
  Fieldset,
  Button,
  TextField,
  Hourglass,
} from "react95";
import Draggable from "react-draggable";
import {
  onDragWindow,
  stakeLP,
  checkMetaMask,
  getCoreData,
  unStakeLP,
} from "src/hooks/operations";

export default function Router({
  active,
  close,
  select,
  isConnected,
  chainId,
  crtAccount,
  curtActiveTab,
}) {
  const windowRef = useRef(null);
  const [state, setState] = useState({
    activeTab: curtActiveTab,
  });
  const [boundMove, setBoundMove] = useState({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  });
  const isMetaMask = checkMetaMask();
  const [eth, setEth] = useState("0.0000");
  const [unstakeVal, setUnstakeVal] = useState("0.0000");
  const [maxToStake, setMaxToStake] = useState(0);
  const [maxToUnStake, setMaxToUnStake] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const handleChange = (e, value) => setState({ activeTab: value });
  const [isStakeing, setIsStakeing] = useState(false);
  const [isUnStaking, setIsUnStaking] = useState(false);
  const { activeTab } = state;

  const handleDrag = () => {
    select();
    const position = onDragWindow(windowRef.current);
    setBoundMove(position);
  };
  const getData = async () => {
    if (isMetaMask && isConnected && crtAccount !== null) {
      const pair = await getCoreData(corePair, pairAbi, "corePair");
      setEth(pair.allowance);
      setMaxToStake(pair.allowance);
      setMaxToUnStake(pair.totalStake);
      setUnstakeVal(pair.totalStake);
      setIsLoading(false);
      // console.log(pair);
    }
  };
  useEffect(() => {
    getData();
  }, [crtAccount, isConnected, isMetaMask]); //eslint-disable-line

  const onStake = async () => {
    setIsStakeing(true);
    try {
      await stakeLP(eth);
      setIsStakeing(false);
      getData();
    } catch (error) {
      setIsStakeing(false);
    }
  };

  const onUnstake = async () => {
    setIsUnStaking(true);
    try {
      await unStakeLP(unstakeVal);
      setIsUnStaking(false);
      getData();
    } catch (error) {
      setIsUnStaking(false);
    }
  };

  return (
    <Draggable
      defaultPosition={{ x: 0, y: 0 }}
      bounds={boundMove}
      position={null}
      onDrag={handleDrag}
      allowAnyClick={true}
      handle=".move"
    >
      <Window
        className="window router"
        ref={windowRef}
        style={active ? { zIndex: 129 } : { zIndex: 109 }}
      >
        <WindowHeader className="window-header" active={active}>
          <span
            className="move"
            style={{ width: "100%", display: "flex", alignItems: "center" }}
            onClick={select}
          >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAJ1BMVEUAAACAgIDAwMAAAAD///8AgIAA//8AAP+AAIAAAID//wCAgAD/AADTdjT5AAAAAXRSTlMAQObYZgAAAAFiS0dEBI9o2VEAAAAHdElNRQfiBBMBJTN2lZVeAAABBklEQVQoz1XQsWrDMBAGYBlBabcKefJmp1O8uFKbwdkSlUB2v4K9hlK4vkKeoHSsm0nZoq1Z82A9KYokH9igD37dnQixxWyRWFmFVTYJzKSUU3jrugAuf4W+72/5Vwt3ALDz+QCfPv/MWNkMCBsPwrZF+LDQIWBM8qECBR5elFJ8eFcbhBK7YkQKPsATgG+LlzK+uHaRLo9fc/8F8E1yZYu7oR/2e0JyTGDeAQ2rsASouyNZPanwaNS/itZsAgUvvNygXWr9mADX3BQ/EbLx9De2vxGKup7XSxMgO2xP49nICO1B1x6InTRft6uzEbMwprisj0ZUcQcqLhLfLdmEitXk7PbG/z/0JkoyIM7uGAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0wNC0xOVQwMTozNzo1MS0wNDowMGGLZNcAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMDQtMTlUMDE6Mzc6NTEtMDQ6MDAQ1txrAAAAAElFTkSuQmCC"
              alt=""
              height="20"
              className="m-r-10"
            />
            <span>Router.eth</span>
          </span>
          <Button onClick={() => close()}>
            <span className="close-icon" />
          </Button>
        </WindowHeader>
        <WindowContent className="window-content" onClick={select}>
          <h1 className="m-b-10">BORE Router v1.21</h1>
          <Tabs value={activeTab} onChange={handleChange}>
            <Tab value={0} className="tab-button">
              Stake
            </Tab>
            <Tab value={1} className="tab-button">
              Unstake
            </Tab>
          </Tabs>
          <TabBody className="tab-body">
            {activeTab === 0 && (
              <div className="tab-content">
                {isLoading ? (
                  <div style={{ margin: "30px auto", textAlign: "center" }}>
                    <Hourglass size={32} />
                  </div>
                ) : (
                  <>
                    {!isConnected || chainId !== workingNetwork ? (
                      <p>Wallet not connected</p>
                    ) : (
                      <>
                        <Fieldset>
                          <div className="d-flex no-responsive m-b-20">
                            <div style={{ display: "flex", flexGrow: 1 }}>
                              <TextField
                                placeholder="0.0000"
                                onChange={(e) => setEth(e.target.value)}
                                value={eth}
                                fullWidth
                                type="number"
                              />
                              <Button onClick={getData}>MAX</Button>
                            </div>
                            <div className="logo-box m-l-15 m-r-15">
                              <img
                                src="images/eth-logo.png"
                                alt=""
                                width="32"
                              />
                            </div>

                            <h2>ETH</h2>
                          </div>
                          <Button
                            fullWidth
                            disabled={
                              eth <= 0 || eth > maxToStake || isStakeing
                            }
                            onClick={onStake}
                          >
                            {isStakeing ? "Staking..." : "STAKE"}
                          </Button>
                        </Fieldset>
                        <div className="m-t-20">
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAFVBMVEUAAACAgAD//wDAwMAAAACAgID////uPuK0AAAAAXRSTlMAQObYZgAAAAFiS0dEBmFmuH0AAAAHdElNRQfiBhoAKi/jBqMDAAAApUlEQVQoz12PwRHDIAwE4ZECwriBCCrIUEDiyH9/6L+VcMIgyfdjZ27FhSCJz+CT8se9I1E9b6A01yDKx+kBWdAbXdJuwErQcJJEld9G0hvMu5FcQCVpAJVcYElwVMCUAGwAU4Jf4OySABSmNUf+/frqHAH5p3NkCO06Z4ARAGlQxpUhGaDwPiXJAkiSrdACy9o8yB1EC2rr1o01B0BoNvjqw4LwB7hqPlTe+r15AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE4LTA2LTI2VDAwOjQyOjQ3LTA0OjAwdbFsfgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOC0wNi0yNlQwMDo0Mjo0Ny0wNDowMATs1MIAAAAASUVORK5CYII="
                            alt=""
                            style={{
                              width: 30,
                              margin: "0 auto",
                              display: "block",
                              marginBottom: 10,
                            }}
                          />
                          <p>
                            All BORE liquidity provider tokens are locked. It is
                            impossible to liquidate these LP tokens in to their
                            underlying assets. However, LP tokens do allow you
                            to participate in the LP farming pool in our
                            BOREVault smart contract, in exchange for your
                            service provided to traders as a liquidity provider.
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
            {activeTab === 1 && (
              <div className="tab-content">
                {isLoading ? (
                  <div style={{ margin: "30px auto", textAlign: "center" }}>
                    <Hourglass size={32} />
                  </div>
                ) : (
                  <>
                    {!isConnected || chainId !== workingNetwork ? (
                      <p>Wallet not connected</p>
                    ) : (
                      <>
                        <Fieldset>
                          <div className="d-flex no-responsive m-b-20">
                            <div style={{ display: "flex", flexGrow: 1 }}>
                              <TextField
                                placeholder="0.0000"
                                onChange={(e) => setUnstakeVal(e.target.value)}
                                value={unstakeVal}
                                fullWidth
                                type="number"
                              />
                              <Button onClick={getData}>MAX</Button>
                            </div>
                            <div className="logo-box m-l-15 m-r-15">
                              <img
                                src="images/eth-logo.png"
                                alt=""
                                width="32"
                              />
                            </div>

                            <h2>ETH</h2>
                          </div>
                          <Button
                            fullWidth
                            disabled={
                              unstakeVal <= 0 ||
                              unstakeVal > maxToUnStake ||
                              isUnStaking
                            }
                            onClick={onUnstake}
                          >
                            {isUnStaking ? "Unstaking..." : "UNSTAKE"}
                          </Button>
                        </Fieldset>
                        <div className="m-t-20">
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAFVBMVEUAAACAgAD//wDAwMAAAACAgID////uPuK0AAAAAXRSTlMAQObYZgAAAAFiS0dEBmFmuH0AAAAHdElNRQfiBhoAKi/jBqMDAAAApUlEQVQoz12PwRHDIAwE4ZECwriBCCrIUEDiyH9/6L+VcMIgyfdjZ27FhSCJz+CT8se9I1E9b6A01yDKx+kBWdAbXdJuwErQcJJEld9G0hvMu5FcQCVpAJVcYElwVMCUAGwAU4Jf4OySABSmNUf+/frqHAH5p3NkCO06Z4ARAGlQxpUhGaDwPiXJAkiSrdACy9o8yB1EC2rr1o01B0BoNvjqw4LwB7hqPlTe+r15AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE4LTA2LTI2VDAwOjQyOjQ3LTA0OjAwdbFsfgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOC0wNi0yNlQwMDo0Mjo0Ny0wNDowMATs1MIAAAAASUVORK5CYII="
                            alt=""
                            style={{
                              width: 30,
                              margin: "0 auto",
                              display: "block",
                              marginBottom: 10,
                            }}
                          />
                          <p>
                            All BORE liquidity provider tokens are locked. It is
                            impossible to liquidate these LP tokens in to their
                            underlying assets. However, LP tokens do allow you
                            to participate in the LP farming pool in our
                            BOREVault smart contract, in exchange for your
                            service provided to traders as a liquidity provider.
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </TabBody>
        </WindowContent>
      </Window>
    </Draggable>
  );
}
