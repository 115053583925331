import React, { useState, useRef, useEffect } from "react";
import {
  Window,
  WindowHeader,
  WindowContent,
  Divider,
  Fieldset,
  Button,
} from "react95";
import Draggable from "react-draggable";
import { onDragWindow } from "src/hooks/operations";

export default function Info({ active, close, select, title }) {
  const windowRef = useRef(null);
  const [data, setData] = useState();

  const [boundMove, setBoundMove] = useState({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  });

  const handleDrag = () => {
    select();
    const position = onDragWindow(windowRef.current);
    setBoundMove(position);
  };

  useEffect(() => {
    const getData = async () => {
      // const data = await getPairInfo();
      // setData(data);
    };
    getData();
  }, []);
  return (
    <Draggable
      defaultPosition={{ x: 0, y: 0 }}
      bounds={boundMove}
      position={null}
      onDrag={handleDrag}
      allowAnyClick={true}
      handle=".move"
    >
      <Window
        className="window info"
        ref={windowRef}
        style={active ? { zIndex: 129 } : { zIndex: 109 }}
      >
        <WindowHeader className="window-header" active={active}>
          <span className="move" style={{ width: "100%" }} onClick={select}>
            {title}
          </span>
          <Button onClick={() => close()}>
            <span className="close-icon" />
          </Button>
        </WindowHeader>
        <WindowContent className="window-content" onClick={select}>
          <div className="tab-content">
            <Fieldset label="Pair Information">
              <p>
                <strong>Pair Information:</strong> 6587
              </p>
              <p>
                <strong>Balance WETH:</strong> {data && data.wEth}
              </p>
              <p>
                <strong>BORE Price:</strong> {data && data.corePrice}
              </p>
            </Fieldset>
            <div className="m-t-20">
              <Fieldset label="LP Token Information">
                <p>
                  <strong>Value:</strong> 3.74 ETH
                </p>
                <p>
                  <strong>Total Supply:</strong> {data && data.totalSupply}{" "}
                  BORE/WETH UNIv2 LP
                </p>
                <p>
                  <strong>Value In LP tokens:</strong> {data && data.lpTokens}{" "}
                  ETH
                </p>
                <div className="m-t-10 m-b-10 divider">
                  <Divider />
                </div>
                <p>
                  At current 6% APY, 1 staked LP token generates{" "}
                  {data && data.lpPerUnit} BORE per unit ( $NaN** )
                </p>
              </Fieldset>
            </div>
            <div className="m-t-20">
              <p>
                ** APY numbers are variable, and depend on BORE transfer volume,
                Ethereum, Bitcoin and BORE prices. BORE is an experiment in
                decentralized finance economics - absolutely no returns are
                guaranteed.
              </p>
            </div>
          </div>
        </WindowContent>
      </Window>
    </Draggable>
  );
}
