import React, { useState, useRef, useEffect } from "react";
import coreAbi from "src/hooks/abis/coreAbi";
import pairAbi from "src/hooks/abis/pair";
import { coreAdd, corePair, workingNetwork } from "src/hooks/address";
import {
  Window,
  WindowHeader,
  WindowContent,
  Tabs,
  Tab,
  TabBody,
  Fieldset,
  Button,
  Avatar,
  Anchor,
  Hourglass,
} from "react95";
import Draggable from "react-draggable";
import {
  onDragWindow,
  connectToWallet,
  sortAddress,
  checkMetaMask,
  getCoreData,
  approveLP,
  stakeLP,
} from "src/hooks/operations";
export default function Wallet({
  active,
  close,
  select,
  openRouter,
  openWeth,
  isConnected,
  connectWallet,
  isConnecting,
  crtAccount,
  chainId,
}) {
  const windowRef = useRef(null);
  const [state, setState] = useState({
    activeTab: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isApproving, setIsApproving] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [boundMove, setBoundMove] = useState({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  });
  const [walletAdd, setWalletAdd] = useState("");
  const [coreData, setCoreData] = useState();
  const handleChange = (e, value) => setState({ activeTab: value });
  const isMetaMask = checkMetaMask();
  const { activeTab } = state;

  const handleDrag = () => {
    select();
    const position = onDragWindow(windowRef.current);
    setBoundMove(position);
  };
  const connect = async () => {
    const res = await connectToWallet();
    if (res === "connected") {
      connectWallet();
    }
  };
  const getData = async () => {
    if (
      isMetaMask &&
      isConnected &&
      crtAccount !== null &&
      chainId === workingNetwork
    ) {
      setIsLoading(true);
      const add = sortAddress(crtAccount);
      setWalletAdd(add);
      const core = await getCoreData(coreAdd, coreAbi, "core");
      const pair = await getCoreData(corePair, pairAbi, "corePair");
      setCoreData({ core, pair });
      console.log({ core, pair });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [crtAccount, isConnected, isMetaMask, chainId]); //eslint-disable-line

  const onApproveLP = async () => {
    setIsApproving(true);
    try {
      await approveLP();
      setIsApproving(false);
      getData();
    } catch (error) {
      setIsApproving(false);
    }
  };

  const onClaim = async () => {
    setIsClaiming(true);
    try {
      await stakeLP("0");
      setIsClaiming(false);
      getData();
    } catch (error) {
      setIsClaiming(false);
    }
  };

  // console.log({ crtAccount, isConnected, isMetaMask, chainId });
  return (
    <Draggable
      defaultPosition={{ x: 0, y: 0 }}
      bounds={boundMove}
      position={null}
      onDrag={handleDrag}
      allowAnyClick={true}
      handle=".move"
    >
      <Window
        className="window"
        ref={windowRef}
        style={active ? { zIndex: 129 } : { zIndex: 109 }}
      >
        <WindowHeader className="window-header" active={active}>
          <span className="move" style={{ width: "100%" }} onClick={select}>
            Wallet.eth
          </span>
          <Button onClick={() => close()}>
            <span className="close-icon" />
          </Button>
        </WindowHeader>
        <WindowContent className="window-content" onClick={select}>
          {isConnecting ? (
            <Hourglass size={32} />
          ) : (
            <>
              {isConnected && chainId !== workingNetwork && isMetaMask && (
                <div style={{ textAlign: "center", paddingTop: 30 }}>
                  <p>Please Change your Network to Kovan</p>
                </div>
              )}
              {!isMetaMask && (
                <div style={{ textAlign: "center" }}>
                  <img
                    src="images/metamask-fox.svg"
                    width="50"
                    alt=""
                    className="m-r-10"
                  />
                  <p>Please Install MetaMask</p>
                </div>
              )}
              {!isConnected && isMetaMask && (
                <div className="d-flex no-responsive justify-center">
                  <Button onClick={connect} style={{ padding: 50 }}>
                    <img
                      src="images/metamask-fox.svg"
                      width="50"
                      alt=""
                      className="m-r-10"
                    />
                    Connect
                  </Button>
                </div>
              )}
              {isConnected && chainId === workingNetwork && (
                <>
                  <div className="m-b-10">
                    <div
                      className="m-b-5"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        size={30}
                        src={
                          "https://sphoto.nasza-klasa.pl/33278012/1/square/2658174fbd.jpeg?v=1"
                        }
                        className="m-r-10"
                      />
                      <p>{walletAdd}</p>
                    </div>
                    <h1>Personal BORE Vault v1.21</h1>
                  </div>
                  <Tabs value={activeTab} onChange={handleChange}>
                    <Tab value={0} className="tab-button">
                      Summary
                    </Tab>
                    <Tab value={1} className="tab-button">
                      Farm
                    </Tab>
                  </Tabs>
                  <TabBody className="tab-body">
                    {activeTab === 0 && (
                      <div className="tab-content">
                        {isLoading ? (
                          <div
                            style={{ margin: "30px auto", textAlign: "center" }}
                          >
                            <Hourglass size={32} />
                          </div>
                        ) : (
                          <>
                            <Fieldset label="BORE">
                              <div className="d-flex">
                                <div className="m-b-10">
                                  <p>Total {coreData && coreData.core.total}</p>
                                  <p>
                                    Wallet {coreData && coreData.core.wallet}
                                  </p>
                                  <p>
                                    Total Claimable{" "}
                                    {coreData && coreData.core.totalClaimable}
                                  </p>
                                </div>
                                <div>
                                  <Button
                                    style={{ width: 90 }}
                                    onClick={() => {
                                      window.open(
                                        `https://app.uniswap.org/#/swap?outputCurrency=${coreAdd}`,
                                        "_blank"
                                      );
                                    }}
                                  >
                                    {coreData && coreData.core.wallet > 0
                                      ? "Get More"
                                      : "Get"}
                                  </Button>
                                  {coreData &&
                                    coreData.core.totalClaimable > 0 && (
                                      <Button
                                        style={{
                                          width: 90,
                                          display: "block",
                                        }}
                                        className="m-t-5"
                                        onClick={onClaim}
                                        disabled={isClaiming}
                                      >
                                        {isClaiming ? "Claiming..." : "Claim"}
                                      </Button>
                                    )}
                                </div>
                              </div>
                            </Fieldset>
                            <div className="m-t-15">
                              <Fieldset label="BORE/WETHLP">
                                <div className="d-flex">
                                  <div className="m-b-10">
                                    <p>
                                      Total {coreData && coreData.pair.total}
                                    </p>
                                    <p>
                                      Wallet {coreData && coreData.pair.wallet}
                                    </p>
                                    <p>
                                      Staked{" "}
                                      {coreData && coreData.pair.totalStake}
                                    </p>
                                  </div>
                                  <div>
                                    {coreData && coreData.pair.wallet <= 0 && (
                                      <Button
                                        style={{ width: 90, display: "block" }}
                                        onClick={() => {
                                          window.open(
                                            `https://app.uniswap.org/#/add/ETH/${coreAdd}`,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        Get
                                      </Button>
                                    )}
                                    {coreData &&
                                      coreData.pair.wallet > 0 &&
                                      coreData.pair.allowance ===
                                        coreData.pair.wallet && (
                                        <Button
                                          style={{
                                            width: 90,
                                            display: "block",
                                          }}
                                          className="m-t-5"
                                          onClick={openRouter}
                                        >
                                          Stake
                                        </Button>
                                      )}
                                    {coreData &&
                                      coreData.pair.wallet > 0 &&
                                      coreData.pair.allowance === "0.0" && (
                                        <Button
                                          style={{
                                            width: 90,
                                            display: "block",
                                          }}
                                          className="m-t-5"
                                          onClick={onApproveLP}
                                          disabled={isApproving}
                                        >
                                          {isApproving
                                            ? "Approving"
                                            : "Approve"}
                                        </Button>
                                      )}
                                  </div>
                                </div>
                              </Fieldset>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    {activeTab === 1 && (
                      <div className="tab-content">
                        <Fieldset label="BORE/WBNB LP">
                          <div className="d-flex">
                            <div>
                              <p>
                                Staked {coreData && coreData.pair.totalStake} LP
                              </p>
                              <p>APY 7%*</p>
                              <p>
                                Claimable{" "}
                                {coreData && coreData.core.totalClaimable}
                              </p>
                              <Anchor className="link-text" onClick={openWeth}>
                                {" "}
                                More Info...
                              </Anchor>
                            </div>
                            <div>
                              <Button
                                style={{
                                  width: 90,
                                  display: "block",
                                }}
                                className="m-t-5"
                                disabled={
                                  coreData && coreData.pair.totalStake <= 0
                                }
                                onClick={(e) => openRouter(e, 1)}
                              >
                                Unstake
                              </Button>
                              <Button
                                style={{
                                  width: 90,
                                  display: "block",
                                }}
                                className="m-t-5"
                                onClick={onClaim}
                                disabled={
                                  (coreData &&
                                    coreData.core.totalClaimable <= 0) ||
                                  isClaiming
                                }
                              >
                                {isClaiming ? "Claiming..." : "Claim"}
                              </Button>
                            </div>
                          </div>
                        </Fieldset>
                        <div className="m-t-20">
                          <p>
                            **APY calculation is calculated as an average of
                            fees over the last 89.42 days, current TVL and BORE
                            price. All future pools will be carefully picked to
                            maximise opportunities for the BORE community.
                          </p>
                        </div>
                      </div>
                    )}
                  </TabBody>
                </>
              )}
            </>
          )}
        </WindowContent>
      </Window>
    </Draggable>
  );
}
