import React, { useState } from "react";

export default function Home() {
  const [bgImage, setBgImage] = useState("images/bg3.png");
  return (
    <>
      <div id="desktop-background" style={{ background: `url(${bgImage})` }}>
        <div className="home-text">
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel
            suscipit diam <strong>$67,985,904</strong> in locked liquidity -{" "}
            <strong>$55,701,675</strong> Nunc at turpis eu urna finibus maximus.
          </p> */}
        </div>
      </div>
      <div
        className="desktop-icon fanny-button"
        onClick={() => setBgImage("images/bg4.png")}
      >
        <img src="images/computer.png" alt="" />
        Enter FannyZone
      </div>
    </>
  );
}
